import React, { useEffect, useState } from "react";
import CornerNav from "../../components/CornerNav";
const { getMembers } = require("../../api");

function MembersGrid({ members }) {
  const DEFAULT_MEMBER = "howdy";
  const [memberHovered, setMemberHovered] = useState(DEFAULT_MEMBER);
  return (
    <div
      onMouseOut={() => {
        setMemberHovered(DEFAULT_MEMBER);
      }}
    >
      <div className="members-grid">
        {members.map((member) => {
          return (
            <a href={member.url}>
              <div
                className="members-grid-member-card"
                onMouseOver={() => {
                  setMemberHovered(member.memberId);
                }}
              >
                <img
                  className="members-grid-member-card-image"
                  alt="member"
                  key={member.id}
                  src={member.imageUrlSmall || "static/images/logo.svg"}
                />
              </div>
            </a>
          );
        })}
      </div>
      <div className="members-grid-member-card-id">{memberHovered}</div>
    </div>
  );
}

function MembersGridContainer() {
  // useEffect to hit getMembers API get method endpoint
  // and set state to the response
  const [isLoading, setIsLoading] = useState(true);
  const [getResponse, setGetResponse] = useState({});
  useEffect(() => {
    setIsLoading(true);
    getMembers()
      .then((response) => {
        try {
          setGetResponse({ status: response.status, data: response.data });
          setIsLoading(false);
        } catch (error) {
          console.log("error reading success response", error);
          setGetResponse({ status: 500 });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        try {
          setIsLoading(false);
          setGetResponse({
            status: error.response.status,
          });
        } catch (e) {
          console.log("error reading error response", e);
          setGetResponse({
            status: 500,
          });
        }
      });
  }, []);

  // if isLoading is true, show loading spinner
  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    if (getResponse.status === 200) {
      return <MembersGrid members={getResponse.data.members} />;
    } else {
      return <div>failed</div>;
    }
  }
}

function About() {
  return (
    <div className="page-about">
      <CornerNav />
      <h1>Members</h1>
      <MembersGridContainer />
    </div>
  );
}

export default About;
