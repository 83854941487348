import React from "react";
import CornerNav from "../../components/CornerNav";

function Gallery() {
  return (
    <div>
      <CornerNav />
      <h1>NFT Gallery</h1>
      <h3>Coming soon...</h3>
    </div>
  );
}

export default Gallery;
