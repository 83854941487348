import React from "react";
import CornerNav from "../../components/CornerNav";

function Entangle() {
  return (
    <div>
      <CornerNav />
      <h1>Howdy Entanglement</h1>
      <h3>Coming soon...</h3>
    </div>
  );
}

export default Entangle;
