import axios from "axios";
const REACT_APP_STAGE = process.env.REACT_APP_STAGE;
const REACT_APP_BACKEND_API_ID = process.env.REACT_APP_BACKEND_API_ID;
const REACT_APP_BACKEND_API_REGION = process.env.REACT_APP_BACKEND_API_REGION;
const API_ENDPOINT = `https://${REACT_APP_BACKEND_API_ID}.execute-api.${REACT_APP_BACKEND_API_REGION}.amazonaws.com/${REACT_APP_STAGE}`;
const GET_MEMBERS_LAMBDA = "getMembers";
export const getMembers = async () => {
  const response = axios.get(`${API_ENDPOINT}/${GET_MEMBERS_LAMBDA}`);
  return response;
};
