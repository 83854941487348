import React from "react";
import {
  ImagesIcon,
  HatCowboyIcon,
  TwitterIcon,
  GavelIcon,
  DnaIcon,
} from "react-line-awesome";

const URL_REALMS =
  "https://realms.today/dao/BjuhR3igPRcaoqH2bKDBx1xbcuFr4VqwgWcMz3zQezYT";
const URL_TWITTER = "https://twitter.com/howdydao";

function Home() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="home-container">
      <div className="home-header">HowdyDAO</div>
      <div
        className={!isOpen ? "menu" : "menu open"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <a className="button" href="/about">
          <HatCowboyIcon />
        </a>
        <a className="button" href="/gallery">
          <ImagesIcon />
        </a>
        <a className="button" href="/entangle">
          <DnaIcon />
        </a>
        <a className="button" href={URL_REALMS}>
          <GavelIcon />
        </a>
        <a className="button" href={URL_TWITTER}>
          <TwitterIcon />
        </a>
      </div>
    </div>
  );
}

export default Home;
