import React from "react";

function CornerNav() {
  return (
    <div className="corner-nav">
      <a href="/">
        <div className="corner-nav-button">
          <div></div>
        </div>
      </a>
    </div>
  );
}

export default CornerNav;
