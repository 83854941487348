import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Gallery from "./pages/Gallery/Gallery";
import Entangle from "./pages/Entangle/Entangle";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

function App() {
  const BasicProps = {};
  const HomeProps = { ...BasicProps, page: "home" };
  const AboutProps = { ...BasicProps, page: "about" };
  const GalleryProps = { ...BasicProps, page: "gallery" };
  const EntangleProps = { ...BasicProps, page: "entangle" };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home {...HomeProps} />} />
          <Route path="/about" element={<About {...AboutProps} />} />
          <Route path="/gallery" element={<Gallery {...GalleryProps} />} />
          <Route path="/entangle" element={<Entangle {...EntangleProps} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
